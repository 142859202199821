<template>
    <div>
        <contacts-component/>
        <footer-component/>
    </div>
</template>

<script>
    import ContactsComponent from "@/components/contact-info/ContactsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Contacts",
        title: "Contactos | Turismo BC",
        components: {
            ContactsComponent, FooterComponent
        }
    }
</script>

<style scoped>

</style>